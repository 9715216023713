
import { defineComponent, nextTick, Transition } from 'vue'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import GameService from '../services/game.service'

interface MobileAppMessageInterface {
  id: number
  mobileappId: number
  entityId: number
  entityLabel: string
  text: string
  response1: string
  response2: string
  response3: string
  response4: string
  unread: boolean
  setRead(): void
}
class MobileAppMessage implements MobileAppMessageInterface {
  id = 0;
  mobileappId = 0;
  entityId = 0;
  entityLabel = '';
  text = '';
  response1 = '';
  response2 = '';
  response3 = '';
  response4 = '';
  unread = false;

  setRead () {
    this.unread = true
  }

  constructor (id: number, mobileappId:number, entityId:number, entityLabel: string, text: string, response1: string, response2: string, response3: string, response4: string, unread: boolean) {
    this.id = id
    this.mobileappId = mobileappId
    this.entityId = entityId
    this.entityLabel = entityLabel
    this.text = text
    this.response1 = response1
    this.response2 = response2
    this.response3 = response3
    this.response4 = response4
    this.unread = unread
  }
}
interface MobileAppInterface {
  id: number
  name: string
  type: string
  action: string
  color1: string
  color2: string
  messages: MobileAppMessage[]
  getUnreadMessagesNb(): void
  getNextUnreadMessages(): void
}
class MobileApp implements MobileAppInterface {
  id = 0;
  name = '';
  type = '';
  action = '';
  color1 = '';
  color2 = '';
  messages = [] as MobileAppMessage[];

  getUnreadMessagesNb () {
    if (Array.isArray(this.messages) && this.messages.length) {
      const unreadMessages = this.messages.filter(message => message.unread)
      return unreadMessages.length
    } else {
      return 0
    }
  }

  getNextUnreadMessages () {
    if (Array.isArray(this.messages) && this.messages.length) {
      const unreadMessages = this.messages.filter(message => message.unread)
      return unreadMessages[0]
    } else {
      return null
    }
  }

  constructor (id: number, name: string, type: string, action: string, color1: string, color2: string, messages: MobileAppMessage[]) {
    this.id = id
    this.name = name
    this.type = type
    this.action = action
    this.color1 = color1
    this.color2 = color2
    this.messages = messages
  }
}

export default defineComponent({
  name: 'Game',
  emits: ['loggedOut'],
  components: {
    Transition,
    PerfectScrollbar
  },
  data () {
    return {
      game: [] as any,
      stats: [] as any,
      mobileApps: [] as MobileApp[],
      checkTimeout: 0 as number,
      message: [] as any,
      tutorialOn: false,
      tutorialSeen: false,
      tutorialStep: 0,
      jeuOn: false,
      atomicOn: false,
      atomicStep: 0,
      atomicTimeout: 0,
      appareilOn: false,
      photosOn: false,
      settingsOn: false,
      textSize: 2,
      soundOn: true,
      statEod: false,
      hastToShowEod: false,
      gameFinished: false,
      presidentialPhoto: '',
      badgeAbeilles: false,
      badgeHirondelles: false,
      badgeCoraux: false,
      badgeThon: false,
      badgeHetre: false,
      badgeGrenouilles: false,
      badgeLynx: false,
      badgeVigne: false,
      atomic: false,
      score: 'moyen',
      unreadMessages: 0
    }
  },
  mounted () {
    if (localStorage.getItem('game')) {
      this.game = localStorage.getItem('game') ? JSON.parse(localStorage.getItem('game')!) : null
      this.presidentialPhoto = localStorage.getItem('presidentialPhoto') ? localStorage.getItem('presidentialPhoto') : this.getImgUrl('img/end_presidente.png')
    }
    if (localStorage.getItem('textSize')) {
      this.textSize = localStorage.getItem('textSize') ? JSON.parse(localStorage.getItem('textSize')!) : 2
    }
    if (localStorage.getItem('soundOn')) {
      this.soundOn = localStorage.getItem('soundOn') ? JSON.parse(localStorage.getItem('soundOn')!) : true
    }
    GameService.getAppsContent().then(
      (responses: any) => {
        this.mobileApps = responses.data.map(
          (response: any) => this.parseAsMobileApp(response)
        )
        this.updateCheck_start()
      },
      (error: any) => {
        if (error.response.status === 401) {
          console.log('error status : ' + error.response.status)
          this.$emit('loggedOut')
        }
        if (error.response) {
          console.log(error.response)
        }
      }
    )
    this.statsUpdate()
    if (localStorage.getItem('tutorialSeen') === null) {
      setTimeout(() => {
        this.showTutorial()
      }, 500)
    }
  },
  methods: {
    progression_set (value: number) {
      window.setTimeout(() => {
        (this.$refs.progression_bar as any).style.width = value + '%'
      }, 50)
    },
    chart_set (stat: string, value: number, valueMax: number) {
      if (valueMax > 100 && value < 0) {
        value = 0
      }
      if (valueMax < value) {
        value = valueMax
      }
      const percent = value / valueMax * 100
      window.setTimeout(() => {
        const strokeDashOffsetValue = 100 - percent
        const chart = this.$refs[stat + 'Chart'] as any
        if (chart) {
          chart.style.strokeDashoffset = strokeDashOffsetValue
          if (stat === 'eod') {
            if (value < 240) {
              chart.style.stroke = '#FF0000'
            } else if (value === 240) {
              chart.style.stroke = '#FFFF00'
            } else {
              chart.style.stroke = '#00FF00'
            }
          } else {
            chart.style.stroke = this.getColor(percent)
          }
        }
      }, 50)
    },
    getColor (pct: number) {
      const percentColors = [
        { pct: 0, color: { r: 0xff, g: 0x00, b: 0 } },
        { pct: 50, color: { r: 0xff, g: 0xff, b: 0 } },
        { pct: 100, color: { r: 0x00, g: 0xff, b: 0 } }
      ]
      for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
          break
        }
      }
      var lower = percentColors[i - 1]
      var upper = percentColors[i]
      var range = upper.pct - lower.pct
      var rangePct = (pct - lower.pct) / range
      var pctLower = 1 - rangePct
      var pctUpper = rangePct
      var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
      }
      return 'rgb(' + [color.r, color.g, color.b].join(',') + ')'
    },
    getUnreadMessagesNb () {
      let unreadMessagesNb = 0
      this.mobileApps.forEach(function (mobileApp) {
        unreadMessagesNb += (mobileApp as MobileApp).getUnreadMessagesNb()
      })
      return unreadMessagesNb
    },
    updateCheck_start () {
      if (this.getUnreadMessagesNb() === 0 && !this.gameFinished) {
        if (this.hastToShowEod) {
          this.hastToShowEod = false
          if (!this.statEod) {
            this.statEod = true
            this.showTutorial(7)
          }
        }
        this.checkTimeout = window.setTimeout(() => {
          this.update_check()
        }, 3000 * Math.random())
      }
    },
    updateCheck_stop () {
      window.clearTimeout(this.checkTimeout)
    },
    update_check () {
      GameService.checkUpdate().then(
        (response: any) => {
          if (response.data.game) {
            this.parseGame(response.data.game)
          }
          if (response.data.end) {
            this.gameHasFinished()
            this.badgeAbeilles = response.data.badgeAbeilles
            this.badgeHirondelles = response.data.badgeHirondelles
            this.badgeCoraux = response.data.badgeCoraux
            this.badgeThon = response.data.badgeThon
            this.badgeHetre = response.data.badgeHetre
            this.badgeGrenouilles = response.data.badgeGrenouilles
            this.badgeLynx = response.data.badgeLynx
            this.badgeVigne = response.data.badgeVigne
          }
          if (response.data.messages) {
            const messages = response.data.messages.map(
              (responseData: any) => this.parseAsMobileAppMessage(responseData)
            )
            if (messages.length > 0) {
              let index = 0
              messages.forEach((message: MobileAppMessage) => {
                const mobileApp = this.mobileApps.find((x:MobileApp) => x.id === message.mobileappId)
                if (mobileApp) {
                  if (!mobileApp.messages) {
                    mobileApp.messages = [] as MobileAppMessage[]
                  }
                  window.setTimeout(() => {
                    mobileApp.messages.push(message)
                    if (this.soundOn) {
                      new Audio('sounds/apps/' + mobileApp.id + '_reception.mp3').play()
                    }
                  },
                  index * 500 + 500 * Math.random()
                  )
                }
                index++
              })
            } else {
              this.updateCheck_start()
            }
          } else {
            this.updateCheck_start()
          }
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('checking update error status : ' + error.response.status)
          }
          if (error.response) {
            console.log('checking update error => ' + error.response)
          }
        }
      )
    },
    parseGame (response: any) {
      this.game.progression = (response.progression) ? response.progression : 0
      this.game.popularity = response.popularity
      this.game.economy = response.economy
      this.game.health = response.health
      this.game.geopolitics = response.geopolitics
      this.game.eod = response.eod
      localStorage.setItem('game', JSON.stringify(this.game))
      this.statsUpdate()
    },
    statsUpdate () {
      this.progression_set(this.game.progression)
      this.chart_set('popularity', this.game.popularity, 100)
      this.chart_set('economy', this.game.economy, 100)
      this.chart_set('health', this.game.health, 100)
      this.chart_set('geopolitics', this.game.geopolitics, 100)
      this.chart_set('eod', this.game.eod, 365)
    },
    parseAsMobileApp (response: any) {
      return new MobileApp(
        response.id,
        response.name,
        response.type,
        response.action,
        response.color1,
        response.color2,
        response.messages
      )
    },
    appClicked (mobileApp: MobileApp) {
      switch (mobileApp.type) {
        case 'method':
          if (mobileApp.action) {
            (this as {[key:string]: any})[mobileApp.action](mobileApp)
          }
          break
      }
    },
    parseAsMobileAppMessage (response: any) {
      return new MobileAppMessage(
        response.id,
        response.mobileapp_id,
        response.entity.id,
        response.entity.label,
        this.convertDataToHtml(JSON.parse(response.text).blocks),
        response.response1,
        response.response2,
        response.response3,
        response.response4,
        true
      )
    },
    getImgUrl (img: string) {
      return require('../assets/' + img)
    },
    sendAnswer (questionId: number, responseId: number) {
      if (this.soundOn) {
        new Audio('sounds/apps/' + this.message.mobileappId + '_send.mp3').play()
      }
      this.hideMessages(false)
      GameService.sendAnswer(questionId, responseId).then(
        (response: any) => {
          if (response.data.game) {
            this.parseGame(response.data.game)
            this.hastToShowEod = true
          }
          if (response.data.messages) {
            const messages = response.data.messages.map(
              (responseData: any) => this.parseAsMobileAppMessage(responseData)
            )
            let index = 0
            messages.forEach((message: MobileAppMessage) => {
              const mobileApp = this.mobileApps.find((x:MobileApp) => x.id === message.mobileappId)
              if (mobileApp) {
                if (!mobileApp.messages) {
                  mobileApp.messages = [] as MobileAppMessage[]
                }
                window.setTimeout(() => {
                  mobileApp.messages.push(message)
                  if (this.soundOn) {
                    new Audio('sounds/apps/' + mobileApp.id + '_reception.mp3').play()
                  }
                },
                index * 500 + 500 * Math.random()
                )
              }
              index++
            })
          } else {
            this.updateCheck_start()
          }
        },
        (error: any) => {
          if (error.response.status === 401) {
            console.log('checking update error status : ' + error.response.status)
          }
          if (error.response) {
            console.log('checking update error => ' + error.response)
          }
          this.updateCheck_start()
        }
      )
    },
    convertDataToHtml (blocks: any) {
      var convertedHtml = ''
      blocks.map((block: any) => {
        switch (block.type) {
          case 'header':
            convertedHtml += '<h' + block.data.level + '>' + block.data.text + '</h' + block.data.level + '>'
            break
          case 'paragraph':
            convertedHtml += '<p>' + this.messageFormat(block.data.text) + '</p>'
            break
          case 'warning':
            convertedHtml += '<div class="response" style="background-color: rgba(0, 0, 0, 0.3);" onClick="document.getElementById(\'infoContent\').innerHTML = \'' + block.data.message.replaceAll("'", "\\'").replaceAll('"', '&quot;') + '\';document.getElementById(\'info\').classList.add(\'show\');">' + block.data.title + '</div>'
            break
          case 'image':
            convertedHtml += '<img class="img-fluid" src="' + block.data.file.url + '" title="' + block.data.caption + '" /><br /><em>' + block.data.caption + '</em>'
            break
          case 'raw':
            convertedHtml += unescape(block.data.html)
            // Adding container and replay btn for video tags
            convertedHtml = convertedHtml.replace('<video ', '<div class="videoContainer"><video ')
            convertedHtml = convertedHtml.replace('</video>', '</video><div class="btnReplay">REJOUER</div></div>')
            break
          default:
            //
            break
        }
      })
      return convertedHtml
    },
    messageFormat (message: string) {
      message = message.replaceAll('@XXX', this.game.first_name + '_' + this.game.last_name)
      message = message.replaceAll('XXX', this.game.first_name + ' ' + this.game.last_name)
      return message
    },
    /* APPS METHODS */
    questionInterractionsSet (questionId: number) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _self = this
      // eslint-disable-next-line dot-notation
      const question = this.$refs['question'] as HTMLElement
      const buttons = question.querySelectorAll('button')
      buttons.forEach(function (button) {
        button.dataset.reactionId = questionId.toString()
        button.addEventListener('click', function (event: Event) {
          event.preventDefault()
          _self.hideMessages(true)
          GameService.sendReactionAnswer(this.dataset.reactionId, this.value).then(
            (response: any) => {
              //
            },
            (error: any) => {
              if (error.response.status === 401) {
                console.log('checking update error status : ' + error.response.status)
              }
              if (error.response) {
                console.log('checking update error => ' + error.response)
              }
            }
          )
        })
      })
      const videos = question.querySelectorAll('video')
      videos.forEach(function (video) {
        video.addEventListener('ended', function (event: Event) {
          event.preventDefault()
          _self.video_ended(event)
        })
      })
    },
    video_ended (event: Event) {
      const video = event.target as HTMLVideoElement
      if (video) {
        const videoContainer = video.parentElement as HTMLElement
        if (videoContainer) {
          const btnReady = videoContainer.querySelector('.btnReplay') as HTMLElement
          if (btnReady) {
            btnReady.classList.add('show')
            btnReady.addEventListener('click', function (event: Event) {
              event.preventDefault()
              video.play()
              this.classList.remove('show')
            })
          }
        }
      }
    },
    showMessages (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideMessages (restartCheck = false) {
      this.message.text = null
      if (restartCheck) {
        this.updateCheck_start()
      }
    },
    showAppels (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideAppels () {
      this.message.text = null
      this.updateCheck_start()
    },
    showMails (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideMails () {
      this.message.text = null
      this.updateCheck_start()
    },
    showAFP (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideAFP () {
      this.message.text = null
      this.updateCheck_start()
    },
    showInstagram (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideInstagram () {
      this.message.text = null
      this.updateCheck_start()
    },
    showYoutube (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideYoutube () {
      this.message.text = null
      this.updateCheck_start()
    },
    showFacebook (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideFacebook () {
      this.message.text = null
      this.updateCheck_start()
    },
    showTwitter (mobileApp: MobileApp) {
      this.message = mobileApp.getNextUnreadMessages()
      this.message.color1 = mobileApp.color1
      this.message.color2 = mobileApp.color2
      this.message.unread = false
      this.message.mobileApp = mobileApp
      this.updateCheck_stop()
      this.$nextTick(() => {
        this.questionInterractionsSet(this.message.id)
      })
    },
    hideTwitter () {
      this.message.text = null
      this.updateCheck_start()
    },
    showButtonRouge (mobileApp: MobileApp) {
      this.atomicStep = 1
      this.atomicOn = true
      this.updateCheck_stop()
    },
    hideButtonRouge () {
      this.atomicOn = false
      this.atomicStep = 1
      clearTimeout(this.atomicTimeout)
    },
    atomic_nextStep () {
      if (this.atomicStep < 5) {
        this.atomicStep++
      }
      if (this.atomicStep === 4) {
        this.$nextTick(() => {
          const atomicVideoContainer = document.getElementById('atomicVideoContainer') as HTMLElement
          atomicVideoContainer.style.zIndex = '-10'
          const atomicVideo = document.getElementById('atomicVideo') as HTMLVideoElement
          atomicVideo.pause()
          this.atomicTimeout = window.setTimeout(() => {
            this.atomic_nextStep()
          }, 10000)
        })
      }
      if (this.atomicStep === 5) {
        this.$nextTick(() => {
          const atomicVideo = document.getElementById('atomicVideo') as HTMLVideoElement
          const atomicVideoContainer = document.getElementById('atomicVideoContainer') as HTMLElement
          atomicVideoContainer.style.zIndex = '1000000'
          atomicVideo.play()
        })
      }
    },
    atomicLaunch () {
      this.atomic = true
      this.atomicOn = false
      this.atomicStep = 1
      this.gameHasFinished()
      clearTimeout(this.atomicTimeout)
    },
    showAppareil () {
      this.appareilOn = true
    },
    hideAppareil () {
      this.appareilOn = false
    },
    showPhotos () {
      this.photosOn = true
    },
    hidePhotos () {
      this.photosOn = false
    },
    showSettings () {
      this.settingsOn = true
    },
    hideSettings () {
      this.settingsOn = false
    },
    texts_setSize (size: number) {
      let fontSize = '1.3vh'
      switch (size) {
        case 1:
          fontSize = '1.1vh'
          break
        case 3:
          fontSize = '1.5vh'
          break
      }
      document.body.style.fontSize = fontSize
      this.textSize = size
      localStorage.setItem('textSize', this.textSize.toString())
    },
    setSound (state: string) {
      if (state === 'on') {
        this.soundOn = true
      } else {
        this.soundOn = false
      }
      localStorage.setItem('soundOn', this.soundOn.toString())
    },
    showLegals () {
      window.open('https://www.callmepresident.fr/Mentions_legales_WWF_Callmepresident.pdf', '_blank')
    },
    hideInfo () {
      const info = document.getElementById('info')
      if (info) {
        info.classList.remove('show')
      }
    },
    showJeu () {
      this.jeuOn = true
    },
    hideJeu () {
      this.jeuOn = false
    },
    showWWF () {
      window.open('https://www.wwf.fr/presidentielle2022', '_blank')
    },
    hideWWF () {
      //
    },
    showTutorial (step = 0) {
      if (isNaN(step)) {
        step = 0
      }
      this.tutorialStep = step
      this.tutorialOn = true
      localStorage.setItem('tutorialSeen', this.tutorialSeen.toString())
      this.$nextTick(() => {
        const tutorialElements = document.getElementById('tutorialElements')
        if (tutorialElements) {
          const tutorialRect = tutorialElements.getBoundingClientRect()
          let srcElement = null as any
          if (this.tutorialStep === 0) {
            const charts = document.getElementById('charts')
            if (charts) {
              srcElement = charts.getElementsByClassName('popularity')[0] as HTMLElement
            }
          }
          if (this.tutorialStep === 1) {
            const charts = document.getElementById('charts')
            if (charts) {
              srcElement = charts.getElementsByClassName('economy')[0] as HTMLElement
            }
          }
          if (this.tutorialStep === 2) {
            const charts = document.getElementById('charts')
            if (charts) {
              srcElement = charts.getElementsByClassName('health')[0] as HTMLElement
            }
          }
          if (this.tutorialStep === 3) {
            const charts = document.getElementById('charts')
            if (charts) {
              srcElement = charts.getElementsByClassName('geopolitics')[0] as HTMLElement
            }
          }
          if (this.tutorialStep === 4) {
            const apps = document.getElementById('apps')
            if (apps) {
              srcElement = apps.getElementsByClassName('app')[0] as HTMLElement
            }
          }
          if (this.tutorialStep === 5) {
            const apps = document.getElementById('apps')
            if (apps) {
              const app = apps.getElementsByClassName('app')[0] as HTMLElement
              if (app) {
                srcElement = app.getElementsByClassName('notifications')[0] as HTMLElement
              }
            }
          }
          if (this.tutorialStep === 6) {
            srcElement = document.getElementById('progress')
          }
          if (this.tutorialStep === 7) {
            const charts = document.getElementById('charts')
            if (charts) {
              srcElement = charts.getElementsByClassName('eod')[0] as HTMLElement
            }
          }
          if (srcElement) {
            const rect = srcElement.getBoundingClientRect()
            const tutorialElement = srcElement.cloneNode(true) as HTMLElement
            tutorialElement.style.left = rect.x - tutorialRect.x + 'px'
            tutorialElement.style.top = rect.y - tutorialRect.y + 'px'
            tutorialElement.style.width = rect.width + 'px'
            tutorialElement.style.height = rect.height + 'px'
            tutorialElements.appendChild(tutorialElement)
          }
        }
      })
    },
    hideTutorial () {
      const tutorialElements = document.getElementById('tutorialElements') as HTMLElement
      if (tutorialElements) {
        tutorialElements.innerHTML = ''
      }
      this.tutorialOn = false
    },
    tutorial_nexStep () {
      if ((this.tutorialStep > 5 && !this.statEod) || (this.tutorialStep > 6 && this.statEod)) {
        this.hideTutorial()
      } else {
        this.tutorialStep++
        this.hideTutorial()
        setTimeout(() => {
          this.showTutorial(this.tutorialStep)
        }, 600)
      }
    },
    tutorial_info () {
      const info = document.getElementById('info')
      const infoContent = document.getElementById('infoContent')
      if (info && infoContent) {
        infoContent.innerHTML = 'Au début du précédent quinquennat, cette date était franchie dès le 5&nbsp;mai, soit le 125ème jour de l\'année, ce qui signifie qu\'il n\'aura pas fallu plus de 4&nbsp;mois à la France pour consommer tout ce que la nature lui a fourni en 1&nbsp;an.<br/><br/>Prenez les bonnes décisions pour réduire l\'empreinte écologique de la France et vous ferez reculer cette date à plus tard dans l\'année. Au contraire, si vous augmentez l\'empreinte de la France, la situation se détériorera et le Jour du dépassement arrivera plus tôt dans l\'année.'
        info.classList.add('show')
      }
    },
    jeuLoad () {
      const iframe = (this.$refs.jeu as any)
      iframe.contentWindow.initJeu({
        gameId: this.game.id
      })
      window.addEventListener('message', this.messageFromJeu)
    },
    messageFromJeu (event: any) {
      window.removeEventListener('message', this.messageFromJeu)
      this.hideJeu()
    },
    inAppMessage (message : string) {
      alert('message : ' + message)
    },
    gameHasFinished () {
      this.gameFinished = true
      this.updateCheck_stop()
      if (this.atomic) {
        this.score = 'atomic'
      } else {
        const moyenne = (this.game.popularity + this.game.economy + this.game.health + this.game.geopolitics) / 4
        if (moyenne < 50) {
          if (this.game.eod < 120) {
            this.score = 'atomic'
          } else if (this.game.eod < 240) {
            this.score = 'game-over'
          } else if (this.game.eod < 360) {
            this.score = 'bas'
          } else {
            this.score = 'moyen'
          }
        } else {
          if (this.game.eod < 120) {
            this.score = 'game-over'
          } else if (this.game.eod < 240) {
            this.score = 'bas'
          } else if (this.game.eod < 360) {
            this.score = 'moyen'
          } else {
            this.score = 'super'
          }
        }
      }
      localStorage.setItem('gameEnded', 'true')
    },
    shareFacebook () {
      const text = encodeURI('Comme ' + this.game.first_name + ' ' + this.game.last_name + ' devenez Président de la République avec notre jeu de simulation #CallmePresident')
      window.open('https://m.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.callmepresident.fr&quote=' + text)
    },
    shareTwitter () {
      const text = encodeURI('Comme ' + this.game.first_name + ' ' + this.game.last_name + ' devenez Président de la République avec notre jeu de simulation #CallmePresident')
      window.open('https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.callmepresident.fr&text=' + text)
    },
    shareLinkedIn () {
      const text = encodeURI('Comme ' + this.game.first_name + ' ' + this.game.last_name + ' devenez Président de la République avec notre jeu de simulation #CallmePresident')
      window.open('https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwww.callmepresident.fr&text=' + text)
    },
    gameRestart () {
      this.$emit('loggedOut')
    }
  }
})
