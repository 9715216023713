
import { defineComponent } from 'vue'
import GameService from '../services/game.service'
import CaptureComponent from './CaptureComponent/CaptureComponent.vue'

export default defineComponent({
  name: 'Login',
  emits: ['loggedIn'],
  components: {
    CaptureComponent
  },
  data () {
    return {
      errors: [] as string[],
      first_name: '',
      last_name: '',
      email: '',
      gender: '',
      optin: false,
      message: '',
      isLogged: this.isLogged,
      userPhoto: '',
      step: 0,
      contentShow: false,
      otpId: '',
      loadingText3: '',
      loadingProgress: 0,
      captureReady: false,
      presidentialPhoto: ''
    }
  },
  mounted () {
    this.step_set(0)
  },
  methods: {
    content_hidden: function () {
      this.step_set(this.step + 1)
    },
    step_next: function () {
      if (this.step < 2) {
        this.contentShow = false
      }
    },
    step_set: function (newStep: number) {
      this.step = newStep
      switch (this.step) {
        case 0:
          this.contentShow = true
          this.introStep1()
          break
        case 1:
          this.contentShow = true
          this.introStep2()
          break
        case 2:
          this.contentShow = true
          this.loginStart()
          break
        case 3:
          this.contentShow = true
          this.captureStart()
          break
        case 4:
          this.contentShow = true
          this.loadingStart()
          break
      }
    },
    introStep1: function () {
      this.$nextTick(() => {
        this.animatedTexts_setup()
        const introText1 = this.$refs.introText1 as HTMLElement
        if (introText1) {
          const spans = introText1.querySelectorAll('span')
          const spansNb = spans.length
          for (let i = 0; i < spansNb; i++) {
            const span = spans[i]
            window.setTimeout(() => {
              span.classList.add('show')
            }, i * 20)
          }
          window.setTimeout(() => {
            this.step_next()
          }, spansNb * 20 + 4000)
        }
      })
    },
    introStep2: function () {
      (this.$refs.animatedBackground as HTMLElement).classList.add('step2')
      this.$nextTick(() => {
        this.animatedTexts_setup()
        window.setTimeout(() => {
          const introText2 = this.$refs.introText2 as HTMLElement
          const spans = introText2.querySelectorAll('span')
          const spansNb = spans.length
          for (let i = 0; i < spansNb; i++) {
            const span = spans[i]
            window.setTimeout(() => {
              span.classList.add('show')
            }, i * 20)
          }
          window.setTimeout(() => {
            (this.$refs.btnStart as HTMLElement).classList.add('show')
          }, spansNb * 20 + 1000)
        }, 1000)
      })
    },
    loginStart: function () {
      //
    },
    captureStart: function () {
      (this.$refs.animatedBackground as HTMLElement).classList.add('step3')
    },
    loadingStart: function () {
      (document.getElementById('login') as HTMLElement).classList.add('loading')
      this.otpId = this.randomString(9, 3)
      this.loadingText3 = this.randomBinaryString(100);
      (this.$refs.animatedBackground as HTMLElement).classList.add('step4')
      this.$nextTick(() => {
        var loadingText3Interval = setInterval(() => {
          this.loadingText3 = this.randomBinaryString(100)
        }, 500)
        var loadingChart1Interval = setInterval(() => {
          this.loadingChart1_update()
        }, 700)
        var loadingChart2Interval = setInterval(() => {
          this.loadingChart2_update()
        }, 1000)
        var loadingProgressInterval = setInterval(() => {
          this.loadingProgress_update()
        }, 3000)
      })
      GameService.sendImage(this.userPhoto).then(
        (response: any) => {
          if (response.data.success) {
            this.presidentialPhoto = response.data.presidentialPhoto
            localStorage.setItem('presidentialPhoto', this.presidentialPhoto)
            window.setTimeout(() => {
              this.$emit('loggedIn')
            }, 3000)
          }
        },
        (error: any) => {
          if (error.response) {
            this.errors = []
            Object.entries(error.response.data.errors)
              .map(([, fieldErrors]) => {
                if (fieldErrors instanceof Array) {
                  fieldErrors.map(fieldError => this.errors.push(fieldError))
                }
              })
          }
        }
      )
    },
    animatedTexts_setup: function () {
      const animatedTexts = document.querySelectorAll('.animatedText')
      const animatedTextsNb = animatedTexts.length
      for (let i = 0; i < animatedTextsNb; i++) {
        const node = document.getElementById(animatedTexts[i].id)
        if (node !== null) {
          const lines = (node as HTMLElement).innerHTML.split('<br>')
          const linesNb = lines.length
          node.innerHTML = ''
          for (let j = 0; j < linesNb; j++) {
            const line = lines[j]
            const lineLetters = Array.from(line)
            const lettersNb = lineLetters.length
            for (let k = 0; k < lettersNb; k++) {
              const letter = lineLetters[k]
              const letterNode = document.createElement('span')
              letterNode.innerHTML = letter
              node.appendChild(letterNode)
            }
            const br = document.createElement('br')
            node.appendChild(br)
          }
        }
      }
    },
    randomString: function (length: number, spacesInterval: number) {
      let string = ''
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      const charsNb = chars.length
      for (let i = 0; i < length; i++) {
        string += chars.charAt(Math.floor(Math.random() * charsNb))
        if (Number.isInteger((i + 1) / spacesInterval)) {
          string += ' '
        }
      }
      return string
    },
    randomBinaryString: function (length: number) {
      let string = ''
      const chars = '0l '
      const charsNb = chars.length
      for (let i = 0; i < length; i++) {
        string += chars.charAt(Math.floor(Math.random() * charsNb))
      }
      return string
    },
    loadingChart1_update: function () {
      const loadingChart1 = document.getElementById('loadingChart1')
      const rows = loadingChart1?.getElementsByTagName('rect')
      if (rows) {
        const rowsNb = rows?.length
        if (rowsNb) {
          let rowWidth = 50 + Math.random() * 10
          for (let i = 0; i < rowsNb; i++) {
            const row = rows[i] as SVGRectElement
            rowWidth = rowWidth * (0.7 + Math.random() * 0.6)
            if (rowWidth > 80) {
              rowWidth = 80
            }
            if (rowWidth < 20) {
              rowWidth = 20
            }
            row.setAttribute('x', rowWidth.toString())
          }
        }
      }
    },
    loadingChart2_update: function () {
      const loadingChart2 = document.getElementById('loadingChart2')
      const columns = loadingChart2?.getElementsByTagName('rect')
      if (columns) {
        const columnsNb = columns?.length
        if (columnsNb) {
          let columnHeight = 50 + Math.random() * 10
          for (let i = 0; i < columnsNb; i++) {
            const column = columns[i] as SVGRectElement
            column.style.opacity = (0.1 + Math.random() * 0.5).toString()
            columnHeight = columnHeight * (0.7 + Math.random() * 0.6)
            if (columnHeight > 80) {
              columnHeight = 80
            }
            if (columnHeight < 20) {
              columnHeight = 20
            }
            column.setAttribute('y', columnHeight.toString())
          }
        }
      }
    },
    loadingProgress_update: function () {
      if (this.loadingProgress >= 100) {
        this.loadingProgress = 0
      } else {
        this.loadingProgress += 25
      }
    },
    onLoginSubmit: function () {
      this.errors = []

      if (!this.first_name) {
        (this.$refs.inFirstName as HTMLElement).classList.add('error')
        this.errors.push('')
      }
      if (!this.last_name) {
        (this.$refs.inLastName as HTMLElement).classList.add('error')
        this.errors.push('')
      }
      if (!this.email) {
        (this.$refs.inEmail as HTMLElement).classList.add('error')
        this.errors.push('')
      } else if (!this.validEmail(this.email)) {
        (this.$refs.inEmail as HTMLElement).classList.add('error')
        this.errors.push('L\'email est invalide')
      }
      if (!this.gender) {
        (this.$refs.rbGender as HTMLElement).classList.add('error')
        this.errors.push('')
      }
      /*
      if (!this.optin) {
        (this.$refs.cbOptin as HTMLElement).classList.add('error')
        this.errors.push('')
      }
      */

      if (!this.errors.length) {
        const formData = new FormData()
        formData.append('first_name', this.first_name)
        formData.append('last_name', this.last_name)
        formData.append('gender', this.gender)
        formData.append('email', this.email)
        formData.append('optin', this.optin ? '1' : '0')
        GameService.sendLogin(formData).then(
          (response: any) => {
            if (response.data.game && response.data.token) {
              localStorage.setItem('game', JSON.stringify(response.data.game))
              localStorage.setItem('tokenGame', JSON.stringify(response.data.token))
              this.contentShow = false
            }
          },
          (error: any) => {
            if (error.response) {
              this.errors = []
              Object.entries(error.response.data.errors)
                .map(([, fieldErrors]) => {
                  if (fieldErrors instanceof Array) {
                    fieldErrors.map(fieldError => this.errors.push(fieldError))
                  }
                })
            }
          }
        )
        return true
      }
    },
    validEmail: function (email:string) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    captureClick () {
      (this.$refs.capture as any).captureImage()
    },
    capturePassClick () {
      if (this.gender === 'H') {
        this.presidentialPhoto = this.getImgUrl('img/end_president.png')
      } else {
        this.presidentialPhoto = this.getImgUrl('img/end_presidente.png')
      }
      localStorage.setItem('presidentialPhoto', this.presidentialPhoto)
      this.$emit('loggedIn')
    },
    resetClick () {
      this.userPhoto = '';
      (this.$refs.capture as any).resetImage()
      this.captureReady = false
    },
    captureOKClick () {
      (this.$refs.capture as any).publishImage()
      this.contentShow = false
    },
    captureDone () {
      this.captureReady = true
    },
    newPicture (imageData: any) {
      this.userPhoto = imageData
    },
    getImgUrl (img: string) {
      return require('../assets/' + img)
    }
  }
})
