
import { defineComponent } from 'vue'
import Login from './components/Login.vue'
import Game from './components/Game.vue'

export default defineComponent({
  name: 'App',
  components: {
    Login,
    Game
  },
  data () {
    return {
      info: null,
      isLoggedIn: false,
      rotateOn: false
    }
  },
  mounted () {
    const appHeight = () => {
      document.body.style.height = window.innerHeight.toString() + 'px'
      if (window.innerWidth < 1024 && window.innerWidth > window.innerHeight) {
        this.rotateOn = true
      } else {
        this.rotateOn = false
      }
    }
    window.addEventListener('resize', appHeight)
    window.addEventListener('orientationchange', appHeight)
    appHeight()
    if (localStorage.game && localStorage.tokenGame && !localStorage.gameEnded) {
      this.isLoggedIn = true
    } else {
      this.logOut()
    }
  },
  methods: {
    hasLoggedIn () {
      this.isLoggedIn = true
    },
    hasLoggedOut () {
      this.logOut()
    },
    logOut () {
      this.isLoggedIn = false
      localStorage.removeItem('game')
      localStorage.removeItem('tokenGame')
      localStorage.removeItem('presidentialPhoto')
      localStorage.removeItem('tutorialSeen')
      localStorage.removeItem('gameEnded')
    }
  }
})
