<template>
  <div id="captureMessage">
    <template v-if="step === 2">
      Pour une expérience optimale, veuillez placer les points de détourage sur les contours de votre visage.
    </template>
    <template v-else>
      Présentez votre visage face à l’objectif.<br/>
      <br/>
      Adoptez une expression neutre avec la tête droite et dégagée. Merci.
    </template>
  </div>
  <div id="captureContainer" ref="captureContainer">
    <div id="videoContainer" ref="videoContainer">
      <video id="video" ref="video" playsinline @loadeddata="startTracking"></video>
      <canvas id="overlayCanvas" ref="overlayCanvas" width="400" height="400"></canvas>
      <canvas id="backgroundCanvas" ref="backgroundCanvas"></canvas>
      <canvas id="captureCanvas" ref="captureCanvas"></canvas>
      <div id="dots" ref="dots"></div>
      <div id="flash" ref="flash"></div>
    </div>
  </div>
</template>

<script>
import Draggabilly from '../../utils/draggabilly.pkgd.min'

export default {
  name: 'CaptureComponent',
  emits: ['captureDone', 'newPicture'],
  data () {
    return {
      lang: 'fr',
      step: 1,
      userPhoto: '',
      dots: [{ x: '50', y: '16' }, { x: '58', y: '20' }, { x: '66', y: '25' }, { x: '74', y: '38' }, { x: '74', y: '60' }, { x: '66', y: '75' }, { x: '58', y: '80' }, { x: '50', y: '84' }, { x: '42', y: '80' }, { x: '34', y: '75' }, { x: '26', y: '60' }, { x: '26', y: '38' }, { x: '34', y: '25' }, { x: '42', y: '20' }],
      pathPoints: [],
      diameter: 0,
      videoWidth: 0,
      videoHeight: 0,
      videoRatio: 0,
      videoScaledWidth: 0,
      videoScaledHeight: 0
    }
  },
  mounted () {
    window.setTimeout(() => {
      this.init()
    }, 1000)
  },
  beforeUnmount () {
    var videoPlayer = document.getElementById('video')
    const stream = videoPlayer.srcObject
    if (stream) {
      const tracks = stream.getTracks()
      tracks.forEach(function (track) {
        try {
          track.stop()
        } catch (error) {
          console.error(error)
        }
      })
    }
    videoPlayer.srcObject = null
  },
  methods: {
    init () {
      const playerVideo = this.$refs.video
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: false, video: { width: { min: 640, ideal: 1920, max: 1920 }, height: { min: 480, ideal: 1080, max: 1080 } } })
          .then(function (stream) {
            playerVideo.srcObject = stream
            playerVideo.play()
          })
          .catch(function (error) {
            console.log('Unable to load webcam stream')
            console.log(error)
          })
      }
    },
    startTracking () {
      this.diameter = this.$refs.captureContainer.clientWidth
      this.$refs.captureContainer.setAttribute('style', 'width: ' + this.diameter + 'px; height: ' + this.diameter + 'px;')
      this.videoWidth = this.$refs.video.videoWidth
      this.videoHeight = this.$refs.video.videoHeight
      this.videoRatio = this.videoWidth / this.videoHeight
      this.videoScaledHeight = this.diameter
      this.videoScaledWidth = this.diameter * this.videoRatio
      if (this.videoRatio < 1) {
        // Portrait Cam
        this.videoScaledWidth = this.diameter
        this.videoScaledHeight = this.diameter / this.videoRatio
      }
      this.$refs.video.setAttribute('style', 'width: ' + this.videoScaledWidth + 'px; height: ' + this.videoScaledHeight + 'px; margin-left: ' + (this.diameter - this.videoScaledWidth) / 2 + 'px; margin-top: ' + (this.diameter - this.videoScaledHeight) / 2 + 'px')
      this.$refs.overlayCanvas.setAttribute('style', 'width: ' + this.diameter + 'px; height: ' + this.diameter + 'px; margin-left: 0px; margin-top: 0px')
      this.$refs.backgroundCanvas.setAttribute('style', 'width: ' + this.videoScaledWidth + 'px; height: ' + this.videoScaledHeight + 'px; margin-left: ' + (this.diameter - this.videoScaledWidth) / 2 + 'px; margin-top: ' + (this.diameter - this.videoScaledHeight) / 2 + 'px')
      this.$refs.captureCanvas.setAttribute('style', 'width: ' + this.videoScaledWidth + 'px; height: ' + this.videoScaledHeight + 'px; margin-left: ' + (this.diameter - this.videoScaledWidth) / 2 + 'px; margin-top: ' + (this.diameter - this.videoScaledHeight) / 2 + 'px')
      this.$refs.dots.setAttribute('style', 'width: ' + this.videoScaledWidth + 'px; height: ' + this.videoScaledHeight + 'px; margin-left: ' + (this.diameter - this.videoScaledWidth) / 2 + 'px; margin-top: ' + (this.diameter - this.videoScaledHeight) / 2 + 'px')
      // Drawing oval to help user
      var ctx = this.$refs.overlayCanvas.getContext('2d')
      ctx.strokeStyle = 'green'
      ctx.setLineDash([10, 5])
      ctx.beginPath()
      ctx.ellipse(400 / 2, 400 / 2, 400 / 4, 400 / 3, Math.PI / 180, 0, 2 * Math.PI)
      ctx.stroke()
    },
    captureImage () {
      this.$refs.video.pause()
      this.$refs.backgroundCanvas.width = this.$refs.video.videoWidth
      this.$refs.backgroundCanvas.height = this.$refs.video.videoHeight
      this.$refs.backgroundCanvas.getContext('2d').drawImage(this.$refs.video, 0, 0, this.$refs.video.videoWidth, this.$refs.video.videoHeight)
      this.$refs.captureCanvas.width = this.$refs.video.videoWidth
      this.$refs.captureCanvas.height = this.$refs.video.videoHeight
      this.$refs.captureCanvas.getContext('2d').drawImage(this.$refs.video, 0, 0, this.$refs.video.videoWidth, this.$refs.video.videoHeight)
      this.$refs.flash.classList.add('on')
      this.$emit('captureDone')
      window.setTimeout(() => {
        this.$refs.flash.classList.remove('on')
        this.dotsSet()
        this.$refs.overlayCanvas.style.opacity = 0
        this.$refs.video.style.opacity = 0
      }, 100)
    },
    resetImage () {
      this.userPhoto = ''
      this.step = 1
      this.$refs.dots.innerHTML = ''
      this.$refs.backgroundCanvas.getContext('2d').clearRect(0, 0, this.$refs.backgroundCanvas.width, this.$refs.backgroundCanvas.height)
      this.$refs.captureCanvas.getContext('2d').clearRect(0, 0, this.$refs.captureCanvas.width, this.$refs.captureCanvas.height)
      this.$refs.overlayCanvas.style.opacity = 1
      this.$refs.video.style.opacity = 1
      this.$refs.video.play()
    },
    publishImage () {
      this.$refs.backgroundCanvas.getContext('2d').clearRect(0, 0, this.$refs.backgroundCanvas.width, this.$refs.backgroundCanvas.height)
      this.addClip(this.$refs.backgroundCanvas.getContext('2d'), this.pathPoints)
      this.$refs.backgroundCanvas.getContext('2d').drawImage(this.$refs.captureCanvas, 0, 0, this.$refs.backgroundCanvas.width, this.$refs.backgroundCanvas.height)
      const rectangle = this.getRectangle(this.pathPoints, this.$refs.backgroundCanvas.width, this.$refs.backgroundCanvas.height)
      const exportCanvas = document.createElement('canvas')
      exportCanvas.id = 'exportCanvas'
      exportCanvas.width = rectangle.width
      exportCanvas.height = rectangle.height
      exportCanvas.getContext('2d').fillStyle = '#00FF00'
      exportCanvas.getContext('2d').fillRect(0, 0, exportCanvas.width, exportCanvas.height)
      exportCanvas.getContext('2d').drawImage(this.$refs.backgroundCanvas, rectangle.x, rectangle.y, rectangle.width, rectangle.height, 0, 0, rectangle.width, rectangle.height)
      this.userPhoto = exportCanvas.toDataURL('image/jpeg')
      this.$emit('newPicture', this.userPhoto)
    },
    dotsSet () {
      this.step = 2
      const dotsNb = this.dots.length
      const overflowWidth = (this.videoScaledWidth - this.diameter) / 2
      const overflowHeight = (this.videoScaledHeight - this.diameter) / 2
      for (let i = 0; i < dotsNb; i++) {
        const dot = this.dots[i]
        const node = document.createElement('div')
        let contentHTML = '<svg data-index="' + i + '" width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">'
        contentHTML += '<circle cx="11" cy="11" r="10" stroke-width="2" stroke="#268200" fill="#003d62"/>'
        contentHTML += '</svg>'
        node.innerHTML = contentHTML
        node.classList.add('dot')
        node.style.top = parseInt(dot.y) * this.diameter / 100 + overflowHeight + 'px'
        node.style.left = parseInt(dot.x) * this.diameter / 100 + overflowWidth + 'px'
        this.$refs.dots.appendChild(node)
      }
      const draggableElems = document.querySelectorAll('.dot')
      const draggies = []
      for (var i = 0; i < draggableElems.length; i++) {
        const draggableElem = draggableElems[i]
        const draggie = new Draggabilly(draggableElem, {
          containment: '#dots'
        })
        draggie.on('dragMove', this.dotMoved)
        draggie.on('dragEnd', this.dragEnd)
        draggies.push(draggie)
      }
      this.updatePositions()
    },
    dotMoved (event, pointer, moveVector) {
      this.updatePositions(parseInt(event.target.dataset.index), moveVector)
    },
    dragEnd (event, pointer) {
      // We put back the top and left in percent
      const dot = event.target.parentNode
      const top = parseFloat(dot.style.top)
      const left = parseFloat(dot.style.left)
      dot.style.top = top
      dot.style.left = left
    },
    updatePositions (index, moveVector = { x: 0, y: 0 }) {
      const dots = this.$refs.dots.querySelectorAll('.dot')
      let path = 'polygon('
      this.pathPoints = []
      for (var i = 0; i < dots.length; i++) {
        const dot = dots[i]
        let top = dot.style.top
        let left = dot.style.left
        if (i === index) {
          top = parseFloat(top)
          left = parseFloat(left)
          top += moveVector.y
          left += moveVector.x
          top += 'px'
          left += 'px'
        }
        path += left + ' ' + top + ', '
        this.pathPoints.push(parseFloat(left))
        this.pathPoints.push(parseFloat(top))
      }
      path = path.substring(0, path.length - 2) + ')'
      this.$refs.captureCanvas.style.clipPath = path
    },
    addClip (context, path) {
      const w = context.canvas.width
      const h = context.canvas.height
      context.beginPath()
      context.moveTo(path[0] / this.videoScaledWidth * w, path[1] / this.videoScaledHeight * h)
      for (let i = 2; i < path.length; i += 2) {
        context.lineTo(path[i] / this.videoScaledWidth * w, path[i + 1] / this.videoScaledHeight * h)
      }
      context.closePath()
      context.clip()
    },
    getRectangle (path, w, h) {
      let minX = w
      let minY = h
      let maxX = 0
      let maxY = 0
      for (let i = 0; i < path.length; i += 2) {
        minX = Math.min(minX, path[i])
        maxX = Math.max(maxX, path[i])
        minY = Math.min(minY, path[i + 1])
        maxY = Math.max(maxY, path[i + 1])
      }
      return { x: minX / this.videoScaledWidth * w, y: minY / this.videoScaledHeight * h, width: (maxX - minX) / this.videoScaledWidth * w, height: (maxY - minY) / this.videoScaledHeight * h }
    }
  }
}
</script>

<style lang="scss" scoped>
#captureMessage {
  position: absolute;
  z-index: 10000;
  top: 13%;
  left: 10%;
  width: 80%;
  font-size: 1.5vh;
  font-weight: 500;
  color:white;
}
#captureContainer {
  position: absolute;
  display: flex;
  align-self: center;
  width: 90%;
  height: 0px;
  border-radius: 50%;
  overflow: hidden;
  justify-content: center;
  border: 3px solid transparent;
  transition: height 0.2s ease-out;
  z-index: 1;
}
#videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
}
#userPhoto {
  position: absolute;
  display: flex;
  align-self: center;
  width: auto;
  height: 100%;
  margin-top: -150px;
  top: 50%;
  overflow: hidden;
  justify-content: center;
  z-index: 10;
}
#video {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
  z-index: 1;
}
#overlayCanvas,
#backgroundCanvas,
#captureCanvas,
#dots,
#flash {
  position: absolute;
  top: 0px;
  left: 0px;
  border: 0px;
  z-index: 2;
}
#backgroundCanvas,
#captureCanvas,
#dots,
#flash {
  width: 100%;
  height: 100%;
}
/*
#backgroundCanvas,
#captureCanvas {
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);
}
*/
#backgroundCanvas {
  opacity: 0.3;
  z-index: 3;
}
#captureCanvas {
  z-index: 4;
}
#dots {
  z-index: 5;
}
#flash {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.8s ease-out;
  pointer-events: none;
  z-index: 6;
}
#flash.on {
  opacity: 0.6;
  transition: opacity 0s;
}

@media (max-device-width: 1024px) and (orientation: portrait) {
  .video-container video,
  .video-container img {
    height: 100% !important;
    width: auto !important;
  }
}
</style>
