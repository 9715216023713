import axios from 'axios'
import authHeader from './auth-header'

const API_URL = 'https://api.callmepresident.fr/api/'

class GameService {
  sendLogin (formData) {
    return axios.post(API_URL + 'game/new', formData, { headers: authHeader() })
  }

  sendImage (imageData) {
    return axios.post(API_URL + 'game/image', {
      file: imageData
    }, { headers: authHeader() })
  }

  getAppsContent () {
    return axios.get(API_URL + 'apps', { headers: authHeader() })
  }

  checkUpdate () {
    return axios.get(API_URL + 'checkupdate', { headers: authHeader() })
  }

  sendAnswer (questionId, responseId) {
    return axios.post(API_URL + 'answer', {
      questionId: questionId,
      responseId: responseId
    }, { headers: authHeader() })
  }

  sendReactionAnswer (reactionId, response) {
    return axios.post(API_URL + 'reaction/answer', {
      reactionId: reactionId,
      response: response
    }, { headers: authHeader() })
  }
}

export default new GameService()
